import { Container, Row, Col } from "react-bootstrap";

function Progress({ font }) {
  return (
    <>
      {/* Calc Select */}
      <Container id="buy" className="fonts-license">
        <Row>
          <Col md={12}>
            <h5 className="mb-3">
              <span className="badge bg-dark">Buy {font.name}</span>
            </h5>
            <p className="pt-3">
              To purchase licensing for {font.name},{" "}
              <a href="/contact">please contact us directly</a>.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Progress;
